import { useContext } from 'react';

import configContext from '../config-context';

// Backwards compatibility to ensure 'Locale' still functions before doing a refactor.
export const useLocale = () => {
  const { locale } = useContext(configContext);
  return locale;
};

const DEV = 'development';
const PROD = 'production';
const STAG = 'staging';

export const useEnvironment = () => {
  const { environment } = useContext(configContext);
  return {
    isDev: environment === DEV,
    isStag: environment === STAG,
    isProd: environment === PROD,
  };
};

export const useSite = () => {
  const { site } = useContext(configContext);
  return site;
};

export const useLanguage = () => {
  const { language } = useContext(configContext);
  return language;
};

export const useConfig = () => useContext(configContext);
