import type { Brand } from '@seek/melways-sites';
import type React from 'react';
import { Helmet as ReactHelmet } from 'react-helmet';

import type { SitesAll } from 'src/shared/types';

import { hreflangs } from './helper';

interface MetaProps {
  title: string;
  brandName: Brand;
  route: string;
  routeName: string;
  host: string;
  enableHreflang?: boolean;
  otherTags?: React.ReactNode;
  site: SitesAll;
}

export const Helmet = ({
  title,
  brandName,
  route,
  routeName,
  host,
  enableHreflang,
  otherTags,
  site,
}: MetaProps) => (
  <ReactHelmet>
    <title>{`${title} | ${brandName}`}</title>
    {otherTags}
    {enableHreflang &&
      hreflangs(routeName).map((e, num) => (
        <link rel="alternate" key={num} {...e} />
      ))}
    {routeName === 'clear-browser-history' && (
      <meta name="robots" content="noindex" />
    )}
    {(routeName === 'contact-us' || site === 'candidate-jobstreet-id') && (
      <link rel="canonical" href={`https://${host}${route}`} />
    )}
  </ReactHelmet>
);
