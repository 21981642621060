import type { Brand, siteConfig } from '@seek/melways-sites';
const defaultDisplayBrand = 'SEEK';

export const capitalise = (value: string) =>
  value.charAt(0).toUpperCase() + value.slice(1);

export const isProduction = () => process.env.NODE_ENV === 'production';

export const isWindow = (): boolean => typeof window !== 'undefined';

export const getQueryString = (n: string) => {
  if (typeof window !== 'undefined' && window.location) {
    const half = window.location.search.split(`${n}=`)[1];
    return typeof half !== 'undefined'
      ? decodeURIComponent(half.split('&')[0])
      : '';
  }
  return '';
};

/* 'au' and 'nz' are not Unification site names, and only implemented as part of this repo's legacy config */
type SiteProps = Exclude<keyof typeof siteConfig, 'test-edge'> | 'au' | 'nz';

export const getCurentSite = (site: SiteProps) => {
  switch (site) {
    case 'nz':
      return 'candidate-seek-nz';
    case 'au':
      return 'candidate-seek-au';
    default:
      return site;
  }
};

export const logHandler = (data: any) => {
  if (!isProduction()) {
    console.log(data); // eslint-disable-line no-console
  }
};

const displayBrands: Record<string, string> = {
  seek: 'SEEK',
  jobsdb: 'Jobsdb',
  jobstreet: 'Jobstreet',
};

export const getDisplayBrand = (brand: Brand = 'seek'): string =>
  displayBrands[brand] || defaultDisplayBrand;

// encode SVG string for css background rendering
export const encodeSvg = (svg: string) =>
  `'data:image/svg+xml;utf8,${encodeURIComponent(svg)}'`;

export const isSeekTestEmail = (email: string) => {
  const SEEK_TEST_EMAIL_REGEX =
    /^[a-zA-Z0-9-_]+\+test[a-zA-Z0-9-_.]*@(seek\.com\.au|seek\.co\.nz|seekasia\.com|myseek\.xyz)$/;
  return Boolean(email.match(SEEK_TEST_EMAIL_REGEX));
};
