import { analytics } from './analytics';
import { isWindow } from './helpers';

export const getPageTrackingData = () => {
  if (isWindow()) {
    return window.currentPageTrackingData;
  }
  return {};
};

export const trackEvent = (
  eventName: string,
  eventProperties?: any,
  eventType?: 'link' | 'view',
) => {
  const currentPageTrackingData = {
    ...getPageTrackingData(),
    eventName,
  };

  analytics[eventType || 'link'](eventName, {
    ...currentPageTrackingData,
    ...eventProperties,
  });
};
