import gql from 'graphql-tag';

export const GET_USER_QUERY = gql`
  query GetUserQuery {
    viewer {
      id
      emailAddress
      personalDetails {
        firstName
        lastName
      }
    }
  }
`;
