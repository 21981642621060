import { Box } from 'braid-design-system';
import React from 'react';

import Logo from './Logo/Logo';

export const BasicHeader = () => (
  <Box paddingX="gutter" paddingY="small" background="surface">
    <Logo />
  </Box>
);
