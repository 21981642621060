import type { Country } from '@seek/melways-sites';

export const getLanguageSwitcher = (
  pathname: string | null,
  country: Country,
) => {
  /**
   * Use pathname that's read after browser has loaded
   * So that the pathname actually uses the actual original path
   * The regex /\/(th|id)(\/|$)/g matches any occurrence of '/th' or '/id' followed by either a '/' or the end of the string.
   * It replaces these occurrences with a single '/'.
   * /identity ❌
   * /identity/ ❌
   * /id/profile ✅ => /profile
   * /th/profile ✅ => /profile
   */
  const pagePath = pathname?.replace(/\/(th|id)(\/|$)/g, '/') ?? '';
  switch (country) {
    case 'id':
      return {
        en: { url: pagePath },
        id: { url: `/id${pagePath}` },
      };
    case 'th':
      return {
        en: { url: pagePath },
        th: { url: `/th${pagePath}` },
      };
  }
  return {};
};

export const hreflangs = (route: string) => [
  {
    hreflang: 'en-my',
    href: `https://my.jobstreet.com/${route}`,
  },
  {
    hreflang: 'en-sg',
    href: `https://sg.jobstreet.com/${route}`,
  },
  {
    hreflang: 'en-ph',
    href: `https://ph.jobstreet.com/${route}`,
  },
  {
    hreflang: 'en-id',
    href: `https://id.jobstreet.com/${route}`,
  },
  {
    hreflang: 'id-id',
    href: `https://id.jobstreet.com/id/${route}`,
  },
  { hreflang: 'en-hk', href: `https://hk.jobsdb.com/${route}` },
  { hreflang: 'en-th', href: `https://th.jobsdb.com/${route}` },
  {
    hreflang: 'th-th',
    href: `https://th.jobsdb.com/th/${route}`,
  },
  {
    hreflang: 'en-au',
    href: `https://www.seek.com.au/${route}`,
  },
  { hreflang: 'en-nz', href: `https://www.seek.co.nz/${route}` },
];
