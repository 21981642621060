import { Box, Loader } from 'braid-design-system';
import React, { useState } from 'react';

import { resolveSource } from './resolveSource';

import * as styles from './Video.css';
export interface VideoProps {
  source?: 'brightcove' | 'youtube';
  id: string;
}

const Video = ({ source = 'brightcove', id }: VideoProps) => {
  const src = resolveSource(source, id);
  const [loaded, setLoaded] = useState(false);

  return (
    <Box
      className={styles.videoWrapper}
      borderRadius={['none', 'large']}
      display="flex"
      justifyContent="center"
    >
      {!loaded && <Loader aria-label="video loading" size="large" />}
      <iframe
        className={styles.video}
        src={src}
        frameBorder="0"
        onLoad={() => setLoaded(true)}
        allowFullScreen
      />
    </Box>
  );
};

export default Video;
