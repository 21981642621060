// import { mapLinkActionHelper } from '@seek/apac-candidate-header-footer';
import React from 'react';

// import { trackEvent } from 'src/utils/trackEvent';

export const linkRenderer = {
  // sendLinkEvent: ({
  //   linkContext: { position, source, openingOrClosing },
  // }: {
  //   linkContext: {
  //     position: string;
  //     source: any;
  //     openingOrClosing?: any;
  //     isExpanded?: boolean;
  //   };
  // }) => {
  //   trackEvent(`${source}-link-clicked`, {
  //     linkName: 'navigation_pressed',
  //     linkPosition: position,
  //     linkText: source,
  //     ...mapLinkActionHelper(openingOrClosing, {
  //       key: 'linkAction',
  //       opening: 'expand',
  //       closing: 'collapse',
  //     }),
  //   });
  // },
  sendLinkEvent: () => {},
};
