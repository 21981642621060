import type { QueryOptions } from '@apollo/client';
import { createClient, createMockClient } from '@seek/ca-graphql-client';
import { browserHttpLink } from '@seek/ca-graphql-client/browser';
import { AuthN, getConfig } from '@seek/online-identity';

import getBrand from 'src/utils/getBrand';

import type { SitesAll } from '../types';

import { GET_USER_QUERY } from './getUserQuery.graphql';

// Types from online-identity package that arent exported...
export interface TestClaims {
  testRecord: boolean;
  testScope?: string;
  recordExpiry?: string;
  testBehaviours?: string;
}

export const getUser = async (
  authOptions: any,
  mock: boolean,
  site: SitesAll = 'candidate-seek-au',
  country: any = 'AU',
) => {
  const brand = getBrand(site) as any;
  let gqlClient: ReturnType<typeof createMockClient | typeof createClient>;

  if (mock) {
    // TODO: Provide mock user data locally, as it
    // is no longer available from the header pkg
    gqlClient = createMockClient();
  }

  const host = window.location.hostname;
  const auth = new AuthN(getConfig('seekAnz-candidate', { host }));

  await auth.init();
  const result = await auth.getTestUserContext();
  const tags: TestClaims | undefined = result || undefined;

  gqlClient = createClient({
    headers: { Accept: 'application/features.seek.all+json' },
    appName: 'SEEK Web Static Pages',
    httpLink: browserHttpLink,
    authOptions: {
      ...authOptions,
      forceStrategy: 'AUTH0',
    },
    brand,
    country,
  });

  const queryGql = (options: QueryOptions) =>
    gqlClient.query({ fetchPolicy: 'no-cache', ...options });

  return queryGql({
    query: GET_USER_QUERY,
  }).then((response) => ({ ...response.data, tags }));
};
