import { useEffect, useState } from 'react';

export const usePathname = () => {
  const [pathname, setPathname] = useState<string | null>(null);

  useEffect(() => {
    if (window) {
      // Remove the origin from the pathname
      // it should return pathname including search params
      setPathname(
        window.location.href.substring(window.location.origin.length),
      );
    }
  }, []);

  return pathname;
};
