import {
  Text,
  Stack,
  List,
  IconPositive,
  Box,
  Heading,
} from 'braid-design-system';
import React from 'react';
import { processNodes, type Transform } from 'react-html-parser';

import { TextLink } from 'src/components';

const transform: Transform = (node, index) => {
  if (node.type === 'tag' && node.name === 'p') {
    return (
      <Text key={index} tone="secondary">
        {processNodes(node.children, transform) as any}
      </Text>
    );
  }

  if (node.type === 'tag' && node.name === 'li') {
    return (
      <Stack space="medium" key={index}>
        {processNodes(node.children, transform) as any}
      </Stack>
    );
  }

  if (node.type === 'tag' && node.name === 'ol') {
    return (
      <List key={index} type="number" space="large">
        {processNodes(node.children, transform) as any}
      </List>
    );
  }

  if (node.type === 'tag' && node.name === 'a') {
    return (
      <TextLink key={index} {...node.attribs}>
        {processNodes(node.children, transform)}
      </TextLink>
    );
  }

  if (
    node.type === 'tag' &&
    node.name === 'ul' &&
    node.attribs.icon === 'checkbox'
  ) {
    return (
      <List key={index} type="icon" icon={<IconPositive />}>
        {processNodes(node.children, transform) as any}
      </List>
    );
  }
  if (node.type === 'tag' && node.name === 'ul') {
    return (
      <List key={index} space="large">
        {processNodes(node.children, transform) as any}
      </List>
    );
  }

  if (node.type === 'tag' && node.name === 'h2') {
    return (
      <Box paddingTop="gutter" key={index}>
        <Heading level="2">
          {processNodes(node.children, transform) as any}
        </Heading>
      </Box>
    );
  }

  if (node.type === 'tag' && node.name === 'h3') {
    return (
      <Box paddingTop="gutter" key={index}>
        <Heading level="3">
          {processNodes(node.children, transform) as any}
        </Heading>
      </Box>
    );
  }

  if (node.type === 'tag' && node.name === 'h4') {
    return (
      <Box paddingTop="gutter" key={index}>
        <Heading level="4">
          {processNodes(node.children, transform) as any}
        </Heading>
      </Box>
    );
  }
};

export const parserOptions = {
  decodeEntities: true,
  transform,
};
