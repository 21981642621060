import { siteConfig as config } from '@seek/melways-sites';

import type { SitesAll } from 'src/shared/types';

const getBrand = (site: SitesAll) => {
  const siteConfig = config;
  // Override candidate-jobsdb-hk languages to en only because zh is not supported.
  siteConfig['candidate-jobsdb-hk'].languages = ['en'];

  const product = siteConfig[site].classification.product;

  if (product === 'candidate') {
    return siteConfig[site].classification.brand;
  }

  switch (siteConfig[site].classification.country) {
    case 'hk':
    case 'th':
      return 'jobsdb';
    case 'id':
    case 'my':
    case 'ph':
    case 'sg':
      return 'jobstreet';
    default:
      return 'seek';
  }
};

export default getBrand;
