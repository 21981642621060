import {
  ApacCandidateFooter,
  ApacCandidateHeader,
  ApacCandidateHeaderFooterLinkProvider,
} from '@seek/apac-candidate-header-footer';
import { siteConfig, type Brand } from '@seek/melways-sites';
import { UivBanner } from '@seek/uiv-banner';
import seekJobs from 'braid-design-system/themes/seekJobs';
import React, { useEffect, type FC, type ReactElement } from 'react';
import { Box, BraidProvider, Page } from 'braid-design-system'; // eslint-disable-line

import { useAuth, AuthProvider } from 'src/shared/auth/AuthProvider';
import { AUTHENTICATION_TYPES } from 'src/shared/auth/authTypes';
import { useConfig } from 'src/shared/hooks';
import { usePathname } from 'src/shared/hooks/usePathname';
import type {
  PageTrackingProps,
  LayoutProps,
  MetaProps,
} from 'src/shared/types';
import { getDisplayBrand, getQueryString, isWindow } from 'src/utils/helpers';
import { trackEvent } from 'src/utils/trackEvent';

import { CustomLink } from '../Link/Link';

import { Helmet } from './Helmet';
import { getLanguageSwitcher } from './helper';
import { linkRenderer } from './linkRenderer';

interface StaticPageProps {
  native?: boolean;
  children: ReactElement[] | ReactElement;
  layoutProps: LayoutProps;
  trackingProps: PageTrackingProps;
  meta?: MetaProps;
  metaTags?: ReactElement[] | ReactElement;
}

const StaticPageWebLayout: FC<StaticPageProps> = ({
  children,
  layoutProps,
  trackingProps,
  metaTags,
}) => {
  const { appLanguage, brand, country, zone, route, routeName, site } =
    useConfig();
  const { host } = siteConfig[site];

  const { authenticationStatus, id, emailAddress, firstName, lastName } =
    useAuth();

  const pathname = usePathname();
  const {
    title,
    returnUrl,
    activeTab,
    noFrame,
    divider,
    minimalHeaderFooter,
    hideFooter,
    enableHreflang,
    isErrorPage,
  } = layoutProps;

  useEffect(() => {
    if (authenticationStatus !== 'pending') {
      const isLoggedIn = authenticationStatus === 'authenticated';
      const authTrackingProps = {
        isLoggedIn,
        ...(isLoggedIn ? { loginId: id } : {}),
      };

      if (isWindow()) {
        window.currentPageTrackingData = {
          zone,
          brand,
          siteLanguage: appLanguage,
          siteCountry: country,
          ...trackingProps,
          ...authTrackingProps,
        };
      }
      trackEvent(
        trackingProps.eventName,
        {
          ...trackingProps,
          ...authTrackingProps,
        },
        'view',
      );
    }
  }, [
    appLanguage,
    authenticationStatus,
    brand,
    country,
    id,
    trackingProps,
    zone,
  ]);

  const metaProps = {
    title,
    brandName: getDisplayBrand(brand) as Brand,
    route,
    routeName,
    host,
    enableHreflang,
    site,
    ...metaTags,
  };

  return (
    <Page
      footer={
        !noFrame &&
        !hideFooter && (
          <ApacCandidateHeaderFooterLinkProvider value={linkRenderer}>
            <ApacCandidateFooter
              authenticationStatus={authenticationStatus}
              isExpandedVersion={!minimalHeaderFooter}
            />
          </ApacCandidateHeaderFooterLinkProvider>
        )
      }
      footerPosition={isErrorPage ? 'belowFold' : undefined}
    >
      {!noFrame && (
        <ApacCandidateHeaderFooterLinkProvider value={linkRenderer}>
          <ApacCandidateHeader
            activeGlobalTab="jobs"
            activePrimaryTab={activeTab}
            authenticationStatus={
              minimalHeaderFooter
                ? AUTHENTICATION_TYPES.AUTH_PENDING
                : authenticationStatus
            }
            divider={divider}
            languageSwitcher={getLanguageSwitcher(pathname, country)}
            leanHeader={minimalHeaderFooter}
            returnUrlForPostAuth={returnUrl}
            userProfile={{
              email: emailAddress,
              firstName,
              lastName,
            }}
          />
        </ApacCandidateHeaderFooterLinkProvider>
      )}
      <Helmet {...metaProps} />
      <div>{children}</div>
    </Page>
  );
};

const StaticPageLayout: FC<StaticPageProps> = ({
  native = false,
  children,
  layoutProps,
  trackingProps,
  meta = {},
}) => {
  const { branch, version } = useConfig();
  const metaTags = Object.keys(meta)
    .map((key) => ({ name: key, content: meta[key] }))
    .map((item) => (
      <meta
        key={item.name}
        name={item.name}
        property={item.name}
        content={item.content}
      />
    ));

  const nativeEmbed = Boolean(getQueryString('native-embed'));
  if (nativeEmbed || native) {
    return <Box>{children}</Box>;
  }

  const { minimalHeaderFooter } = layoutProps;

  return (
    <AuthProvider authBypass={Boolean(minimalHeaderFooter)}>
      <BraidProvider theme={seekJobs} linkComponent={CustomLink}>
        <UivBanner
          applicationName="wpt-static-pages"
          branch={branch}
          version={version}
        />
        <StaticPageWebLayout
          layoutProps={layoutProps}
          trackingProps={trackingProps}
          metaTags={metaTags}
        >
          {children}
        </StaticPageWebLayout>
      </BraidProvider>
    </AuthProvider>
  );
};

export default StaticPageLayout;
