import React, {
  createContext,
  useEffect,
  useState,
  useContext,
  type ReactNode,
} from 'react';

import { logHandler } from 'src/utils/helpers';

import configContext from '../config-context';
import { useConfig } from '../hooks';

import { AUTHENTICATION_TYPES, type AuthInfo } from './authTypes';
import { getUser } from './getUser';

const initialAuthState = {
  authenticationStatus: AUTHENTICATION_TYPES.AUTH_PENDING,
  emailAddress: '',
  firstName: '',
  lastName: '',
};
const AuthContext = createContext<AuthInfo>(initialAuthState);

export const AuthProvider = ({
  authBypass,
  children,
}: {
  authBypass: boolean;
  children: ReactNode;
}) => {
  const { useMockedAuth } = useContext(configContext);

  const { site, country } = useConfig();

  const [authStatus, setAuthStatus] = useState<AuthInfo>(initialAuthState);

  useEffect(() => {
    // Skip user auth check if already authenticated or authBypass is true
    if (
      authStatus.authenticationStatus === AUTHENTICATION_TYPES.AUTHENTICATED ||
      authBypass
    ) {
      return;
    }

    getUser({ useAuth: true }, useMockedAuth, site, country.toUpperCase())
      .then((data: any) => {
        const {
          viewer: {
            id,
            emailAddress,
            personalDetails: { firstName, lastName },
          },
          tags,
        } = data;
        setAuthStatus({
          error: undefined,
          authenticationStatus: AUTHENTICATION_TYPES.AUTHENTICATED,
          emailAddress,
          firstName,
          lastName,
          id,
          tags,
        });
      })
      .catch((err: any) => {
        logHandler(err);
        setAuthStatus({
          error: err,
          authenticationStatus: AUTHENTICATION_TYPES.UNAUTHENTICATED,
          emailAddress: '',
          firstName: '',
          lastName: '',
          id: undefined,
        });
      });
  }, [
    useMockedAuth,
    authStatus.authenticationStatus,
    authBypass,
    site,
    country,
  ]);

  return (
    <AuthContext.Provider value={authStatus}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
