import { linkContextDestructor } from '@seek/apac-candidate-header-footer';
import { useMelwaysLink } from '@seek/melways-react';
import {
  type Link as BraidLink,
  ButtonLink as BraidButton,
  TextLink as BraidTextLink,
  makeLinkComponent,
} from 'braid-design-system';
import type React from 'react';

import { useConfig } from 'src/shared/hooks';
import { getQueryString } from 'src/utils/helpers';
import { trackEvent } from 'src/utils/trackEvent';

export const CustomLink = makeLinkComponent((props, ref) => (
  <Link {...props} ref={ref} />
));

type LinkProps = React.ComponentProps<typeof BraidLink> & {
  tracking?: any;
  component?:
    | string
    | typeof BraidLink
    | typeof BraidButton
    | typeof BraidTextLink;
  product?: 'candidate' | 'employer';
  skipResolver?: boolean;
  eventProperties?: any;
};

export const Link = ({
  tracking,
  eventProperties,
  component: LinkComponent = 'a',
  children,
  href = '/',
  product,
  skipResolver,
  ...restProps
}: LinkProps) => {
  const { appLanguage, brand } = useConfig();

  // Remove the specific HeaderFooter props and have them
  // available for use in the trackEvent function below
  const [props, headerProps] = linkContextDestructor(
    restProps,
    (linkContext) => {
      const { source, position, isExpanded } = linkContext;
      return {
        eventName: `${source}-link-clicked`,
        linkName: 'navigation_pressed',
        linkPosition: isExpanded ? 'expanded menu' : position,
        linkText: source,
      };
    },
  );

  // If it is a external link we do not want to use the resolver or if it is a telephone link
  const isExternalLink = href.startsWith('http');
  const isTelephoneLink = href.startsWith('tel:');
  const skipHrefResolver = skipResolver || isExternalLink || isTelephoneLink;
  const removeLanguagePath = (pageHref: string) => {
    // remove extra language path from non-english language
    if (pageHref.startsWith(`/${appLanguage}/`)) {
      return pageHref.substring(3);
    }

    return pageHref;
  };

  let resolvedHref = useMelwaysLink({
    language:
      headerProps?.linkText === 'languageSwitcher - en' ? 'en' : appLanguage,
    product,
    path: removeLanguagePath(href),
    brand,
  });

  const absoluteUrlRegExp = new RegExp('^(?:[a-z]+:)?//', 'i');
  if (
    Boolean(getQueryString('native-embed')) && // Current page has been opened in native mode with passing native-embed=true
    !absoluteUrlRegExp.test(href) && // the href is a relative path, eg /privacy
    !skipResolver && // the path should be resolved by melways
    href.substring(0, 1) === '/' // the path is a vaild relative path, eg it is not a hash path starting with #
  ) {
    // if all above conditions are met, this link should open the page in native mode by adding native-embed=true query string
    resolvedHref = `${resolvedHref}${
      resolvedHref.indexOf('?') > 0 ? '&' : '?' // if there already are query strings the new one should be added by "&" otherwise "?"
    }native-embed=true`;
  }

  const onClick = () => {
    trackEvent(headerProps ? headerProps.eventName : tracking, {
      ...eventProperties,
      ...headerProps,
    });
  };

  const combinedProps = {
    onClick,
    href: skipHrefResolver ? href : resolvedHref,
    ...props,
  };

  return <LinkComponent {...combinedProps}>{children}</LinkComponent>;
};

type ButtonProps = Omit<LinkProps, 'component'> &
  React.ComponentProps<typeof BraidButton>;

export const ButtonLink = (props: ButtonProps) => (
  <Link component={BraidButton} {...props} />
);

type TextLinkProps = Omit<LinkProps, 'component'> &
  React.ComponentProps<typeof BraidTextLink>;

export const TextLink = (props: TextLinkProps) => (
  <Link component={BraidTextLink} {...props} />
);

export default Link;
