import { adapter } from '@seek/tealium-adapter';

import { isProduction } from './helpers';

const telium = adapter();

const DEBUG_TRACKING = false;

const shouldTrackEvent = isProduction() || DEBUG_TRACKING;

/**
 * A proxy of @seek/tealium-adapter that disables tracking on dev environments.
 *
 * To debug tracking events in production, see the telium docs:
 * https://github.com/SEEK-Jobs/tealium-adapter#debugging-adapter-locally
 */
export const analytics = new Proxy(telium, {
  get(target, prop: keyof typeof telium) {
    // If it's not a function we will pass it through.
    if (typeof target[prop] !== 'function') {
      return Reflect.get(target, prop);
    }

    return (...fnArgs: any[]) => {
      if (!shouldTrackEvent) {
        // eslint-disable-next-line no-console
        console.log('TRACKING DISABLED for this environment. Event details:', {
          prop,
          ...fnArgs,
        });
        return null;
      }

      return Reflect.apply(target[prop], target, fnArgs);
    };
  },
});
