export enum AUTHENTICATION_TYPES {
  AUTHENTICATED = 'authenticated',
  UNAUTHENTICATED = 'unauthenticated',
  AUTH_PENDING = 'pending',
}

export interface AuthInfo {
  authenticationStatus:
    | AUTHENTICATION_TYPES.AUTH_PENDING
    | AUTHENTICATION_TYPES.AUTHENTICATED
    | AUTHENTICATION_TYPES.UNAUTHENTICATED;
  emailAddress: string;
  firstName: string;
  lastName: string;
  error?: any;
  tags?: any;
  id?: number;
}
